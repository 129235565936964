import React from 'react'
import * as newVolumeStyles from "../styles/newVolumeRangeStyles.module.scss"; 


const NewVolumeRange= ()=>{
    return(
        <div className={newVolumeStyles.newVolumeRange}>
            <input
            type="range"
             type='range' 
             min={0} 
             max={1} 
             step='any' 
            />
        </div>
    )
}

export default NewVolumeRange;