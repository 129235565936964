import React from 'react'
import NewVolumeRange from '../components/videoplayer/components/newVolumeRange'


const NewVolumeRangePage = ()=> {
    return(
        <NewVolumeRange/>  
    )
}

export default NewVolumeRange;